import { useContext } from 'react';
import { Typography } from "antd";
import CardList from "./components/AppointmentsGrid";
import MainContext from './context/main/context';

const Appointments = () => {
    const { appointments, nextAppointments } = useContext(MainContext);
    return (
        <>
            <CardList appointments={nextAppointments} />
            <Typography.Title level={5} style={{marginTop:24}}>Citas pasadas</Typography.Title>
            <CardList appointments={appointments} />
        </>
    )
}

export default Appointments;