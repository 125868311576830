import React from "react";
import { Typography, Layout, Row, Col, Card, Form, Input, Button } from "antd";
import medicalImage from "../../assets/medical2.png";
import { LockFilled } from "@ant-design/icons";
import "../Login/login.css";
const { Text } = Typography;
// const passwordSymbols = RegExp(/\w+([!#$%&()*+,\-.\/:;<=>?@[\]^_{|}~'"`])*([a-zA-Z0-9!#$%&()*+,\-.\/:;<=>?@[\]^_{|}~'"`]{8,})/)
// const passwordSymbols = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&|.])[A-Za-z\d@$!%*?&|.]{8,}$/)
const passwordSymbols = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/
);

const NewPassword = (props) => {
  const { auth } = props;
  const [form] = Form.useForm();

  const onSubmit = (formData) => {
    let field = "";
    auth.user.email ? (field = 'email'): (field = 'phone');
    auth.newPassword(
      auth.user[field],
      formData.password,
      formData.confirmPassword
    );
  };

  return (
    <Layout
      style={{ height: "100vh", position: "relative", overflow: "hidden" }}
    >
      <Row
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Col
          xs={20}
          sm={14}
          md={12}
          lg={10}
          xl={10}
          xxl={10}
          style={{ textAlign: "center", height: "100%", display: "flex", alignItems: "center" }}
        >
          <Card className="card" style={styles.card}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Text style={styles.description}>
              Configura tu nueva contraseña con al menos una mayúscula, un numero y un símbolo
              </Text>
              <Form.Item
                name="password"
                rules={[
                  { required: true },
                  () => ({
                    validator(_, value) {
                      if (value && passwordSymbols.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Debe tener al menos una mayuscula, un numero y un simbolo"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input
                  style={styles.input}
                  type="password"
                  prefix={<LockFilled style={styles.icons} />}
                  placeholder="Constaseña"
                  disabled={auth.loading}
                />

                {/* <Input type="password" disabled={auth.loading} /> */}
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "¡Las dos contraseñas que ingresó no coinciden!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input
                  style={styles.input}
                  type="password"
                  prefix={<LockFilled style={styles.icons} />}
                  placeholder="Confirmar contraseña"
                  disabled={auth.loading}
                />

                {/* <Input type="password" disabled={auth.loading} /> */}
              </Form.Item>
              <Form.Item>
                <Button
                  style={styles.button}
                  htmlType="submit"
                  disabled={auth.loading}
                >
                  Confirmar contraseña
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <img className="bgImage" src={medicalImage} style={styles.image} />
    </Layout>
  );
};

const styles = {
  description: {
    marginBottom: 19,
    display: 'block',
    lineHeight: 1.3,
    fontSize: '1.3rem',
  },
  container: {
    padding: 24,
    width: "80%",
    backgroundColor: "rgba(255,255,255,0.42)",
    borderRadius: "8px",
    position: "relative",
    marginTop: "8%",
    boxShadow: "12px 12px 12px -3px rgb(0 0 255 / 10%)",
  },
  image: {
    maxHeight: "100vh",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
  },
  card: {
    width: "100%",
    padding: '30px',
    zIndex: "10000",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center"
  },
  formTitle: {},
  input: {
    borderRadius: "4px",
    padding: "10px 10px 10px 15px",
  },
  button: {
    width: "100%",
    background: "#00CEC8",
    border: "none",
    color: "#fff",
    fontWeight: "600",
    borderRadius: "4px",
  },
  icons: {
    color: "#00CEC8",
  },
  link: {
    textDecoration: "underline",
  },
};

export default NewPassword;
