import { useEffect, useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const usePatientData = (user, {
    localStorageCache,
    onError
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);

    const fetchData = async (user, background = false) => {
        let res = undefined;
        if (!background) setLoading(true);
        try {
            res = await axios.get(`${apiUrl}/patient/medic_record/show?elementables=InitialPlan,Prognostic,Surgery,MedicStudy,RequiredEvaluation,Status,SurgeryDocuments`, {
                headers: {
                    token: user.accessToken
                }
            });
        } catch (error) {
            if (!background) setLoading(false);
            return onError(error);
        }

        let newData = mapPatientData(res.data, user);

        setData(newData);
        if (localStorageCache) {
            window.localStorage.setItem("pData", JSON.stringify(newData));
        }
        if (!background) setLoading(false);
    };

    useEffect(() => {
        if (user && user.challenge !== 'NEW_PASSWORD_REQUIRED') {
            let parsedLocalData = undefined;
            let localData = window.localStorage.getItem("pData");
            if (localData && localData !== 'null') {
                parsedLocalData = JSON.parse(localData);
            }

            if (parsedLocalData && parsedLocalData.user.id == user.id) {
                setData(parsedLocalData);
                setLoading(false);
                fetchData(user, true);
            } else {
                fetchData(user);
            }
        }
    }, [user]);
    return {
        loading,
        data,
        refetch: () => fetchData(user)
    }
};

const mapPatientData = (raw, user) => {
    let medicRecord = raw.medic_record?.medic_record;

    const prediagnostic = raw.share_forms.find(s => s.prediagnostic === true);

    const filterOutSurgeries = a => a.appointmentDetail.name !== "Valoración Preanestésica" && a.appointmentDetail.appointment_category.name !== "Cirugía";
    let appointments = raw.appointments.filter(filterOutSurgeries).filter(a => a.status === 'Checked-in').map(a => mapAppointment(a, prediagnostic));
    let nextAppointments = raw.appointments.filter(filterOutSurgeries).filter(a => a.status !== 'Checked-in').map(a => mapAppointment(a, prediagnostic));

    let plan = medicRecord?.InitialPlan && medicRecord.InitialPlan.length > 0 ? medicRecord.InitialPlan[0] : undefined;

    let studies = medicRecord?.MedicStudy.map(ms => mapStudy(ms));

    let appointmentStartFilter = a => a.type === "Consulta Bariatría";
    let pendingSurgeries = medicRecord?.Surgery ? medicRecord?.Surgery.filter(s => ['toSchedule'].includes(s.status)).map(s => mapSurgery(s)) : undefined;

    let prediagnosticWithoutAppointment = !plan && prediagnostic?.status === "answered" && !nextAppointments.find(appointmentStartFilter)

    let pendingPlanDecision = plan && plan.status === "Ask Again";

    const surgeryResults = medicRecord?.SurgeryDocuments?.elementable?.surgery_documents_results?.map(el => {
        let splitName = el.result && el.result.split('/');
        if(splitName) {
            el.name = splitName[splitName.length -1]
        }
        return el
    })

    return {
        nextAppointments,
        appointments,
        medicRecord,
        surgeryDocuments: medicRecord?.SurgeryDocuments?.elementable?.surgery_documents_results,
        shareForms: raw.share_forms,
        prediagnostic: prediagnostic && ["created", "viewed"].includes(prediagnostic.status) ? prediagnostic : undefined,
        studies: studies ? studies : [],
        surgeries: medicRecord?.Surgery ? medicRecord?.Surgery.filter(s => ['pending', 'scheduled', 'ready'].includes(s.status) && s.elementable.appointment?.AppointmentHospital).map(s => mapSurgery(s)) : [],
        surgery: pendingSurgeries && pendingSurgeries.length > 0 ? pendingSurgeries : undefined,
        plan: {
            almost:  prediagnosticWithoutAppointment && !appointments.find(appointmentStartFilter),
            waiting: prediagnosticWithoutAppointment && appointments.find(appointmentStartFilter),
            pending: pendingPlanDecision,
            ...(plan ? mapPlan(plan, medicRecord) : {}),
        },
        user: {
            id: user.id,
            name: user.name,
            email: user.email
        }
    };
};

const mapAppointment = (a, prediagnostic) => {
    return {
        type: a.appointmentDetail.name,
        date: new Date(a.start).toLocaleString(),
        prediagnostic: a.appointmentDetail.name === "Consulta Bariatría" && prediagnostic && ["created", "viewed"].includes(prediagnostic.status),
        specialistName: a.specialists && a.specialists.length > 0 ? `${a.specialists[0].user.specialist_profile.title} ${a.specialists[0].user.specialist_profile.name}` : ' - ',
        toConfirm: a.status === "Por confirmar",
        status: a.status,
    };
};

const mapPlan = (plan, medicRecord = {
    MedicStudy: [],
    RequiredEvaluation: [],
}) => {
    const elementInPlanFilter = (statuses) => (el) => statuses.includes(el.status) && el._type === 'protocol' && (el.checklistStatus.short_name === 'pot' || el.checklistStatus.short_name === 'pba');
    let pendingStudies = medicRecord.MedicStudy.filter(elementInPlanFilter(['pending'])).map(s => mapStudy(s));
    return {
        pendingApproval: plan.status !== 'emitted' && pendingStudies.length === 0 && medicRecord.RequiredEvaluation.filter(elementInPlanFilter(['toSchedule', 'pending'])).length === 0,
        description: plan.elementable.description,
        status: plan.status,
        studies: pendingStudies,
        evaluations: medicRecord.RequiredEvaluation.filter(elementInPlanFilter(['toSchedule'])).map(re => mapEvaluation(re)),
    };
};

const mapStudy = (study) => {
    return {
        status: study.status,
        isProtocol: study._type === 'protocol',
        checklist: study.checklistStatus.short_name,
        name: study.elementable.systemElement.name,
        date: study.status === 'delivered' ? new Date(study.elementable.updatedAt).toLocaleString() : undefined,
    };
};

const mapEvaluation = (evaluation) => {
    return {
        status: evaluation.status,
        isProtocol: evaluation._type === 'protocol',
        checklist: evaluation.checklistStatus.short_name,
        name: evaluation.elementable.systemElement.name,
    };
};

const mapSurgery = (surgery) => {
    return {
        status: surgery.status,
        name: surgery.elementable.systemElement.name,
        hospital: surgery.elementable.appointment?.AppointmentHospital?.hospital?.name,
        date: surgery.elementable.appointment?.start ? new Date(surgery.elementable.appointment.start).toLocaleString() : undefined,
        needsConfirm: surgery.elementable.appointment?.status === "Por confirmar",
    }
};

export default usePatientData;