import { useContext, useState } from 'react';
import { Layout, Menu, Typography } from 'antd';
import { HomeOutlined, CalendarOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './App.css';
import AuthContext from './context/auth/context';
import usePatientData from './hooks/usePatientData';
import Login from './components/Login';
import NewPassword from './components/Login/newPassword';
import MainContext from './context/main/context';

import Home from './Home';
import Appointments from './Appointments';
// import Protocol from './Protocol';

const { Header, Content, Footer } = Layout;

const VIEWS = {
  home: {
    name: 'Inicio',
    icon: <HomeOutlined />,
    Component: Home
  },
  appointments: {
    name: 'Citas',
    icon: <CalendarOutlined />,
    Component: Appointments
  },
  // protocol: {
  //   name: 'Protocolo',
  //   icon: <AuditOutlined />,
  //   Component: Protocol
  // }
}

const App = ({
  view = "home"
}) => {
  const [error, setError] = useState(undefined);
  const auth = useContext(AuthContext);
  const { loading, data } = usePatientData(auth.user, {
    localStorageCache: true,
    onError(error) {
      console.error(error);
      setError(error);
    }
  });
  const [ currentView, setView ] = useState(view);
  const { Component } = VIEWS[currentView];

  if (!auth.user) {
    return <Login auth={auth} />;
  }

  if(auth && auth.user && auth.user.challenge === 'NEW_PASSWORD_REQUIRED') {
    return <NewPassword auth={auth} />
  }

  if (loading) {
    return <>...</>;
  } else if (error) {
    return <>Failed</>;
  }

  const newPatient = data?.nextAppointments.length === 0 &&
    data.appointments.length === 0 &&
    !data.medicRecord &&
    data.shareForms.length === 0;

  return (
    <Layout className="layout">
      <Header style={styles.header}>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[currentView]}>
          {
            Object.keys(VIEWS).map(v => (
              <Menu.Item onClick={
                currentView !== v
                  ? () => setView(v)
                  : undefined
                }
                key={v}
                icon={VIEWS[v].icon}
                disabled={newPatient}
              >
                  { VIEWS[v].name }
              </Menu.Item>
            ))
          }
          <Menu.Item style={styles.menuItem} onClick={auth.signOut}>
            Cerrar Sesión
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={styles.content}>
        {
          !newPatient && currentView === 'home' &&
          <Typography.Title style={styles.title} level={3}>
            👋 Hola de nuevo {auth.user.name}!
          </Typography.Title>
        }
        <MainContext.Provider value={{
            newPatient,
            // NOTE: data already includes user basic information
            ...data,
        }}>
            <Component />
        </MainContext.Provider>
      </Content>
      <Footer style={styles.footer}>{`Innovare ©${new Date().getFullYear()}`}</Footer>
    </Layout>
  );
};

const styles = {
  header: { position: 'fixed', zIndex: 1, width: '100%' },
  menuItem: {marginLeft:'auto'},
  content: { padding: '75px 50px',minHeight: "calc(100vh - 75px)" },
  title: {marginTop: 24, marginBottom: 24},
  footer: { textAlign: 'center' },
};

export default App;