import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { Line } from "react-chartjs-2";

const WeightChart = ({ allNutritionEvaluations, styles }) => {


    const dataNotSorted = allNutritionEvaluations && allNutritionEvaluations.length > 0 ? allNutritionEvaluations.map((el, index) => {
        if (el.status === "delivered") {
            const { elementable: { share_form: { extracted_metrics, id } } } = el;
            const weight = extracted_metrics && extracted_metrics.find(m => m.category === 'weight');
            const wvalue = weight && weight.type === "lb" ? (+weight.value) / 2.205 : weight.value;
            return {
                id: id,
                kg: wvalue ? wvalue : 0,
                status: el.status,
                evaluationId: el.id,
            }
        } else {
            return {
                evaluationId: el.id,
                status: el.status
            }
        }
    }) : []

    dataNotSorted.length > 0 && dataNotSorted.sort(function (a, b) {
        return a.evaluationId - b.evaluationId
    })

    const dataWeight = dataNotSorted.length > 0 && dataNotSorted.map((el, index) => {
        if (el.status === "delivered") {
            return { ...el, name: `Seguimiento ${index + 1}` }
        } else {
            return { ...el }
        }
    })

    // console.log("dataWeight_", dataWeight);
    const weightCalculation = dataWeight.length > 0 ? calculateWightLoss(dataWeight.filter(s => s.status === "delivered")) : 0;
    console.log('lost__', weightCalculation);


    const configWeightChart = dataWeight && {
        labels: dataWeight.filter(s => s.status === "delivered").map(s => s.name),
        datasets: [
            {
                label: "Peso (kg)",
                data: dataWeight.filter(s => s.status === "delivered").map(s => s.kg),
                borderColor: "#1890ff",
                tension: 0.4
            },
        ]
    };

    const chartOptions = {
        plugins: {
            legend: { position: 'bottom', display: false },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                display: false,
                offset: true
            },
            y: {
                grid: {
                    display: false,
                },

            },
        },
    }


    return (
        <Row>
            <Col xs={20} sm={20} md={20} lg={15} xl={15} xxl={15}>
                <div style={{ ...styles.mb7, display: 'flex', flexDirection: 'column', padding: "20px" }}>
                    <span style={{ marginBottom: "15px", fontWeight: "500" }}>Seguimiento de tu peso (kg)</span>
                    <div style={componentStyles.weightInfoContainer}>
                        {
                            weightCalculation &&
                                <div style={componentStyles.wightCalculationContainer}>
                                    {
                                        weightCalculation < 0
                                            ? <div><><span style={componentStyles.weightTitle}>{Math.abs(weightCalculation)}</span><span>kg</span></><RiseOutlined style={{...componentStyles.icon}} /> </div>
                                            : <div><><span style={componentStyles.weightTitle}>{Math.abs(weightCalculation)}</span><span>kg</span></><FallOutlined style={{...componentStyles.icon, color: '#449E64'}} /> </div>
                                    }
                                    <span style={componentStyles.weightDesc}>
                                        {`Haz ${weightCalculation < 0 ? 'aumentado' : 'bajado'} ${Math.abs(weightCalculation)} kilos`}
                                    </span>

                                </div>
                        }
                        <div style={componentStyles.chartContainer}>
                            <Line data={configWeightChart} options={chartOptions} />
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}


export default WeightChart;

function calculateWightLoss(data) {
    const firstWeight = +(data[0].kg);
    const lastWeight = +(data[data.length - 1].kg);
    return (firstWeight - lastWeight);
}

const componentStyles = {
    weightInfoContainer:{
        display: 'flex'
    },
    wightCalculationContainer:{
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        width:'30%'
    },
    weightTitle:{
        fontSize: '24px', 
        fontWeight: '600'
    },
    weightDesc: {
        fontWeight: '600'
    },
    icon: {
        fontSize: '26px'
    },
    chartContainer: {
        width: '70%', 
        marginLeft: '20px'
    }


}