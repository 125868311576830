import { Card, Empty, List, Tooltip } from 'antd';
import { InfoCircleOutlined, CalendarOutlined } from '@ant-design/icons';

function CardList({
    appointments = []
}) {

    if (appointments.length === 0) {
        return <Empty description="No tienes citas programadas" style={{marginTop:62}}/>;
    }

    return (
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4,
                xxl: 3,
            }}
            dataSource={appointments}
            renderItem={item => (
                <List.Item>
                    <Card
                        title={item.specialistName}
                        type="inner"
                        extra={
                            <>
                            {
                                item.prediagnostic &&
                                <Tooltip
                                    title="Debes contestar tu cuestionario de pre-diagnóstico previo a tu cita"
                                >
                                    <InfoCircleOutlined style={{color:'#1890ff'}} />
                                </Tooltip>
                            }
                            {
                                item.toConfirm && !item.prediagnostic &&
                                <Tooltip
                                    title="No ovlides confirmar tu cita con Atención a Clientes"
                                >
                                    <InfoCircleOutlined style={{color:'#1890ff'}} />
                                </Tooltip>
                            }
                            </>
                        }
                    >
                        <Card.Meta
                            title={item.type}
                            description={<><CalendarOutlined /> {item.date}</>}
                        />
                    </Card>
                </List.Item>
            )}
        />
    )
}

export default CardList;