import { useContext } from 'react';
import { Button, message, Card, List, Space, Tag, Tooltip } from 'antd';
import { CalendarOutlined, CheckCircleTwoTone, CheckSquareOutlined, CloudDownloadOutlined, DeliveredProcedureOutlined, InfoCircleOutlined, InfoCircleTwoTone, ScheduleOutlined } from '@ant-design/icons';
import axios from 'axios';

import CardList from './components/AppointmentsGrid';
import MainContext from './context/main/context';
import MedicalMessage from './components/MedicalMessage';
import { Line } from 'react-chartjs-2';
import WeightChart from './components/Charts/Weight/WeightChart';

const MAIN_CLIENT_URL = process.env.REACT_APP_MAIN_CLIENT_URL;
const API_URL = process.env.REACT_APP_API_URL;

function Home() {
    const {
        nextAppointments,
        newPatient,
        user,
        prediagnostic,
        plan,
        studies,
        surgeryDocuments,
        surgeries,
        surgery,
        medicRecord
    } = useContext(MainContext);
    let followUpPatient = false;
    let weightChartData;

    if (newPatient) {
        return <Welcome name={user.name} />;
    }

    if(medicRecord && Object.keys(medicRecord).length > 0){
        if(medicRecord.RequiredEvaluation.length > 1){
            const { RequiredEvaluation } = medicRecord;
            const allNutritionEvaluations = RequiredEvaluation && RequiredEvaluation.length > 0 ? RequiredEvaluation.filter(ev => ev.status === "delivered" && ev.elementable.systemElement.name === "Seguimiento Nutrición Bariátrica" || ev.status === "pending" && ev.elementable.systemElement.name === "Seguimiento Nutrición Bariátrica") : [];
            if(allNutritionEvaluations && allNutritionEvaluations.filter(e => e.status === "delivered").length > 1){
               followUpPatient = true;
               weightChartData = allNutritionEvaluations;
            }
        }
    }

    const Appointments = () => <AppointmentsCard
        title="Próximas Citas"
        empty="No tienes ninguna cita programada"
        appointments={nextAppointments}
    />;

    if (plan.almost||plan.waiting||plan.pending||plan.pendingApproval||(plan.status==='emitted'&&surgery)) {
        let title = undefined;
        let message = undefined;

        if (plan.almost) {
            title = <><InfoCircleTwoTone /> Estás a un paso de poder iniciar tu protocolo</>;
            message = "Contacta a Atención a Clientes para que puedas agendar tu Consulta de Bariatría y poder iniciar con tu proceso bariátrico."
        } else if (plan.waiting) {
            title = <><CheckCircleTwoTone twoToneColor="#52c41a" /> ¡Gracias por completar la primera etapa!</>;
            message = "Nuestros especialistas están evaluando tu perfil y muy pronto te proveerán con un plan que deberás aceptar. Mantente atento a cualquier indicación por parte de nuestro equipo.";
        } else if (plan.pending) {
            title = <><CheckCircleTwoTone twoToneColor="#52c41a" /> ¡Gracias por completar la primera etapa!</>;
            message = <PlanDescription plan={plan} />;
        } else if (plan.pendingApproval) {
            title = <><CheckCircleTwoTone twoToneColor="#52c41a" /> ¡Gracias por completar la segunda etapa!</>;
            message = "Nuestros especialistas estan evaluando tu perfil médico para maximizar los resultados del proceso bariátrico. Mantente atento a cualquier indicación por parte de nuestro equipo.";
        } else/* if (plan.status === 'emitted' && surgery) */{
            title = <><CheckCircleTwoTone twoToneColor="#52c41a" /> ¡Felicidades, tu plan de protocolo ha sido aprobado!</>;
            message = "Nuestros especialistas han aprobado tu perfil médico y todo parece estar en orden para empezar a programar la cirugía. Contacta a Atención a Clientes para que puedas proporcionar una fecha tentativa.";
        }

        return (
            <>
                <FullMessage
                    title={title}
                    message={message}
                />
                {nextAppointments.length > 0 && <div style={styles.mtDefault}><Appointments /></div>}
            </>
        );
    }

    return (
        <>
        {
            followUpPatient && weightChartData &&
            <WeightChart styles={styles} allNutritionEvaluations={weightChartData} />
        }
        {
            surgeries.length > 0 &&
            <SurgeryCard surgery={surgeries[0]} surgeryDocuments={surgeryDocuments} user={user}/>
        }
        {
            prediagnostic &&
            <ActionRequired
                title="Pre Diagnóstico"
                description="Debes contestar tu cuestionario de pre-diagnóstico previo a tu consulta de Bariatría."
                actions={[
                    {
                        label: "Contestar ahora",
                        props: {
                            href: `${MAIN_CLIENT_URL}${prediagnostic.url}`,
                            type: "primary",
                            target: "blank"
                        }
                    }
                ]}
            />
        }
        {studies.filter(s => s.status !== 'delivered').length === 0 && <Appointments />}
        {
            studies && studies.length > 0 && 
            <Studies studies={studies} />
        }
        {studies.filter(s => s.status !== 'delivered').length > 0 && <Appointments />}

       
        </>
    );
}

const AppointmentsCard = ({ title, appointments, empty }) => (
    <Card title={<><ScheduleOutlined/> {title}</>} style={styles.mb7}>
        {
            appointments.length === 0
                ? empty
                : <CardList appointments={appointments} />
        }
    </Card>
);

const FullMessage = ({ title, message }) => (
    <div style={styles.fullMessageContainer}>
        <MedicalMessage
            title={title}
            message={message}
        />
    </div>
)

const Welcome = ({ name }) => (
    <FullMessage
        title={`Bienvenid@ ${name}!`}
        message="Gracias por confiar en nosotros y elegirnos para tus procesos bariátricos. Aquí podrás encontrar información acerca de tus citas y protocolo. Al parecer aún no hay ninguna acción a realizar, si crees que esto es incorrecto por favor contacta a Atención a Clientes o vuelve más tarde."
    />
);

const ActionRequired = ({
    mainTitle = "Acción Requerida",
    title,
    description,
    actions = [],
}) => (
    <Card style={styles.mb7} title={<><InfoCircleOutlined style={{color:'#1890ff'}} /> { mainTitle }</>}>
        <Space direction="vertical">
            <Card.Meta style={styles.mb7} title={title} />
            { description }
            <Space>
                {
                    actions.map((a,i) => (
                        <Button style={styles.mt7} key={i} {...a.props}>{ a.label }</Button>
                    ))
                }
            </Space>
        </Space>
    </Card>
);

const PlanDescription = ({
    plan
}) => (
    <>
        Antes de continuar con el protocolo debes aceptar el plan propuesto por nuestros especialistas:
        <br />
        <br />
        Plan Quirúrgico:<br /><li>{plan.description}</li>
        {plan.studies && <><br /><br />Estudios:
        <br />
        {
            plan.studies.map(s => (
                <li>{s.name}</li>
            ))
        }</>}
        {plan.evaluations && <><br /><br />Evaluaciones:
        <br />
        {
            plan.evaluations.map(s => (
                <li>{s.name}</li>
            ))
        }</>}
        <br />
        <br />
        Comunicate con Atención a Clientes cuando tengas una decisión, no dudes en contactarnos para aclarar cualquier duda o inquietud.
    </>
);

const Studies = ({
    studies,
}) => {
    let pendingStudies = studies.filter(s => s.status !== 'delivered');
    let actionTitle = pendingStudies.length === 0 ? <><CheckCircleTwoTone twoToneColor="#52c41a" /> Acción Completada</> : <><InfoCircleOutlined style={{color:'#1890ff'}} /> Acción Requerida</>;
    return (
        <>
        {
            studies.length > 0 &&
            <Card style={styles.mb7} title={actionTitle}>
                <Space direction="vertical" style={{width:'100%'}}>
                    <Card.Meta style={styles.mb7} title="Entrega de estudios" />
                    {
                        pendingStudies.length === 0
                            ? "Haz entregado todos tus estudios"
                            : "Debes realizarte y entregar los siguientes estudios"
                    }
                    <List
                        style={styles.mt7}
                        itemLayout="horizontal"
                        dataSource={pendingStudies}
                        locale={{emptyText:" "}}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<DeliveredProcedureOutlined />}
                                    title={item.name}
                                    description={
                                        <>
                                            Lugar: {item.hospital ? item.hospital : "  -  "}<br />
                                            Fecha: {item.date ? item.date : "  -  "}
                                        </>}
                                />
                            </List.Item>
                        )}
                    />
                    <List
                        itemLayout="horizontal"
                        dataSource={studies.filter(s => s.status === 'delivered')}
                        locale={{emptyText:" "}}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<CheckSquareOutlined style={{color:'green'}} />}
                                    title={<Space direction="horizontal"><div>{item.name}</div><Tag color="green" style={{marginLeft:4}}>Entregado</Tag></Space>}
                                    description={
                                        <>
                                            Lugar: {item.hospital ? item.hospital : "  -  "}<br />
                                            Fecha: {item.date ? item.date : "  -  "}
                                        </>}
                                />
                            </List.Item>
                        )}
                    />
                </Space>
            </Card>
        }
        </>
    );
};

const downloadDocument = async function() {
    let localUser = window.localStorage.getItem("user");
    let token = null
    if (localUser && localUser !== 'null') {
        const user = JSON.parse(localUser)
        token = user?.accessToken
    }
    if(token) {
        try {
            const res = await axios.get(
                `${API_URL}/patient/share_medic_record_element/downloadSurgeryDocument/${this.id}` ,
                {headers:{token}}
            );
            const link = document.createElement("a");
            link.download = "document";
            link.href = res?.data?.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            message.error('Algo salió mal por favor intente más tarde');
        }
    }
}

const SurgeryCard = ({
    surgery,
    surgeryDocuments,
    user
}) => {
    return (
        <>
            {surgeryDocuments && surgeryDocuments.length > 0 && <Card style={styles.mb7} title={<><InfoCircleOutlined style={{color:'#1890ff'}} /> Acción Requerida</>}>
                <Space direction="vertical">
                    <Card.Meta style={styles.mb7} title="Entrega de documentos" />
                    Debes imprimir y firmar los siguientes documentos previo a tu cirugía
                    <Space direction="horizontal" style={styles.mt7}>
                        {
                            surgeryDocuments.map(sd => (
                                <Button 
                                    type="primary" 
                                    onClick={downloadDocument.bind(sd)} 
                                    icon={<CloudDownloadOutlined />}
                                >{sd.name}</Button>
                            ))
                        }
                    </Space>
                </Space>
            </Card>}
            <Card
                style={styles.mb7}
                title={<><CalendarOutlined /> {surgery.name}</>}
                extra={
                    <Tooltip
                        title={
                            surgery.needsConfirm
                                ? "No olvides confirmar tu cirugía con anticipación comunicándote a Atención a Clientes"
                                : "Debes entregar los documentos requeridos firmados previo a la cirugía"
                        }
                    >
                        <InfoCircleOutlined style={{color:'#1890ff'}} />
                    </Tooltip>
                }
            >
                <Card.Meta
                    title={surgery.hospital}
                    description={surgery.date}
                />
            </Card>
        </>
    )
};

const styles = {
    mb7: {
        marginBottom: 7
    },
    mt7: {
        marginTop: 7
    },
    mtDefault: {
        marginTop: '12%',
    },
    fullMessageContainer: {
        height:'70vh',
        width:'100%'
    }
};

export default Home;