import { Layout, Row, Col, Card, Form, Input, Button, Typography } from 'antd';
import { useState } from 'react';
import Forgot from './forgot';
import medicalImage from '../../assets/medical2.png';
import { LockFilled, MailFilled, MailTwoTone } from "@ant-design/icons"
import '../Login/login.css'
const passwordSymbols = RegExp( /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/)
const phone = RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)
const email = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)


const Login = ({
    auth
}) => {
    const [form] = Form.useForm();
    const [forgot, setForgot] = useState(null);

    const onSubmit = (formData) => {
        let field = 'email';
        if((/[+][0-9]+/).test(formData.email)){
            field = 'phone' 
        }
        auth.signIn(formData.email, formData.password, field);
    }
    
    return (
        <Layout style={{ height: '100vh', position:"relative", overflow:"hidden" }}>
            <Row className="container" style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent:"center" }}>
                <Col className="card-container" xs={20} sm={14} md={12} lg={10} xl={10} xxl={10} style={{ textAlign: 'center', height: "100%"}}>
                    <Card className="card" style={styles.card}>
                        {
                            !forgot ?
                                <div>
                                    <h2>Bienvenido</h2>
                                    <Form requiredMark={false} layout="vertical" form={form} name="control-hooks" onFinish={onSubmit}>
                                        <Form.Item 
                                            name="email"
                                            validateStatus={auth && !auth.user && !auth.error ? undefined : auth && auth.loading ? 'validating' : auth && auth.error ? "error" : 'success'}
                                            rules={[
                                                { required: true,
                                                  message: "Ingresa email o teléfono"
                                                
                                                },
                                                () => ({
                                                validator(_,value){
                                                    if(!value || email.test(value) || phone.test(value)){
                                                        return Promise.resolve();  
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            "Ingresa un email o teléfono válidos"
                                                        )
                                                    )
                                                }
                                                })
                                            ]}
                                        >
                                            <Input style={styles.input} prefix={<MailFilled style={styles.icons} />} placeholder="Email o telefono" disabled={auth.loading} />
                                        </Form.Item>
                                        <Form.Item 
                                            name="password"
                                            validateStatus={auth && !auth.user && !auth.error ? undefined : auth && auth.loading ? 'validating' : auth && auth.error ? "error" : 'success' }
                                            rules={[
                                                { required: true },
                                                () => ({
                                                    validator(_, value) {
                                                      if (!value || passwordSymbols.test(value)) {
                                                        return Promise.resolve();
                                                      }
                                                      return Promise.reject(
                                                        new Error(
                                                          "Debe tener al menos una mayuscula, un numero y un simbolo"
                                                        )
                                                      );
                                                    },
                                                  })
                                            ]}
                                        >
                                            <Input style={styles.input} type="password" prefix={<LockFilled style={styles.icons}/>} placeholder="Password" disabled={auth.loading} />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button style={styles.button} htmlType="submit" disabled={auth.loading}>Iniciar Sesión</Button>
                                        </Form.Item>
                                        <div style={{fontSize: '12px'}}>
                                            <span style={{color: "#949CAC"}}>¿Olvidaste tu contraseña?</span>
                                            <a style={{...styles.link, marginLeft: '5px'}} onClick={() => setForgot(true)}>Restablecer contraseña</a>
                                        </div>
                                    </Form>
                                </div>

                                :
                                <>
                                    <Forgot styles={styles} setForgot={setForgot} />
                                </>
                        }

                    </Card>
                </Col>
            </Row>
            <img className="bgImage" src={medicalImage} style={styles.image} />
        </Layout>
    )
};

const styles = {
    container: {
        padding: 24,
        width: '80%',
        backgroundColor: 'rgba(255,255,255,0.42)',
        borderRadius: '8px',
        position: 'relative',
        marginTop: '8%',
        boxShadow: '12px 12px 12px -3px rgb(0 0 255 / 10%)'
    },
    image: {
        maxHeight: '100vh',
        position: "absolute", 
        top:"0", 
        right: "0", 
        bottom: "0" 
    },
    card: {
        width: '100%', 
        padding: '30px',
        zIndex: "10000",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center"
    },
    formTitle:{

    },
    input:{
        borderRadius: '4px',
        padding: '10px 10px 10px 15px'
    },
    button: {
        width: '100%', 
        background: '#00CEC8', 
        border:'none', 
        color: '#fff', 
        fontWeight: '600',
        borderRadius: '4px'
    },
    icons:{
        color: '#00CEC8'
    },
    link: {
        textDecoration: 'underline'
    },
    
};

export default Login;