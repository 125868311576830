import { useState, useEffect } from 'react';
import AuthContext from './context';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default function Auth(props) {
    const [state, setState] = useState({
        user: undefined,
        loading: true,
        error: undefined
    });

    useEffect(() => {
        let newState = {
            ...state,
            loading: false,
        };

        if (state.user) {
            window.localStorage.setItem("user", JSON.stringify(state.user));
        } else {
            let localUser = window.localStorage.getItem("user");
            if (localUser && localUser !== 'null') {
                newState.user = JSON.parse(localUser)
            }
        }
        setState(newState);
    }, [state.user]);

    const signIn = async (value, password, field) => {
        setState({
            ...state,
            loading: true
        });

        let newState = {
            ...state
        };

        const handle = (apiRes) => {
            newState = {
                ...newState,
                ...signInResponseToState(apiRes),
            };
            setState(newState);
        };

        let res = undefined;
        const data = {
            password: password
        };
        if(field === 'phone'){
            data.phone = value
        }else{
            data.email = value
        }
        try {
            res = await axios.post(`${apiUrl}/login`, data)
        } catch (err) {
            if (err.response) {
                return handle(err.response);
            } else {
                throw new Error(err);
            }
        }

        handle(res);
    };

    const newPassword = async (val, password, confirmPassword) => {
        setState({
            ...state,
            loading: true
        });

        const handle = (apiRes) => {
            let newState = {
                ...state,
                loading: false,
                user: { 
                    ...state.user, 
                    challenge: null,
                    ...apiRes.data
                }
            };
            setState(newState);
        };

        let res = undefined;
        const data ={
            password,
            confirmPassword
        }
        if(val.includes("@"))data.email = val;
        else data.phone = val;

        try {
            res = await axios.post(`${apiUrl}/confirmPassword`, data);
            handle(res);

        } catch (err) {
            if (err.response) {
                // return handle(err.response);
            } else {
                throw new Error(err);
            }
        }

    }

    const signOut = async () => {
        setState({
            ...state,
            loading: true
        });

        await axios.post(`${apiUrl}/logout`);
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("pData");
        setState({
            ...state,
            loading: false,
            user: undefined
        });
    };

    return (
        <AuthContext.Provider value={{
            ...state,
            signIn,
            signOut,
            newPassword,
        }}>
            { props.children }
        </AuthContext.Provider>
    )
}

const signInResponseToState = (res) => {
    let newState = {
        loading: false
    };

    if (res.status === 200 && (res.data.role === 'patient' || (res.data.user && res.data.user.role === 'patient'))) {
        newState.user = res.data; 
    } else if (res.data) {
        let errorMessage = "Unknown error";
        if (res.data.errors?.message) {
            errorMessage = res.data.errors.message;
        } else if (res.data.message) {
            errorMessage = res.data.message;
        }
        newState.error = errorMessage;
    } else {
        newState.error = "Unknown error";
    }

    return newState;
}