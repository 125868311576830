import { Typography } from 'antd';
import medicalImage from '../../assets/medical.png';

const { Title } = Typography;

export default function({
    title,
    message,
}) {
    return ( 
        <>
            <div style={styles.container}>
                <Title level={1}>{title}</Title>
                <Title level={5}>{message}</Title>
            </div>
            <img src={medicalImage} style={styles.image}/>
        </>
    );
}

const styles = {
    container: {
        padding:24,
        width:'80%',
        backgroundColor:'rgba(255,255,255,0.42)',
        borderRadius:'8px',
        position:'relative',
        marginTop:'8%',
        boxShadow:'12px 12px 12px -3px rgb(0 0 255 / 10%)'
    },
    image: {
        width:'100%',
        maxWidth:'700px',
        marginLeft:'auto',
        display:'flex',
        marginTop:'-32%'
    }
};