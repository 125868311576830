import React, { useState } from 'react'
import { Form, Input, Button, Alert } from 'antd';
import axios from 'axios';
import { LeftCircleOutlined, LockFilled, MailFilled, SafetyCertificateFilled } from '@ant-design/icons';

const apiUrl = process.env.REACT_APP_API_URL;

const Forgot = ({ setForgot, styles }) => {
    const [formRequestCode] = Form.useForm();
    const [formNewPassword] = Form.useForm();
    const [requestedCode, setRequestedCode] = useState(false);
    const [error, setError] = useState(null);

    const onSubmitRequestCode = (values) => {
        const data = {};
        if(values.email.includes("@")){
            data.email = values.email;
        }else{
            if((/\d/).test(values.email)){
                data.phone = values.email
            }
        }
        // console.log("data__", data)
        axios.post(`${apiUrl}/forgot`, data).then(
            res => {
                if (res.status === 200) {
                    //    console.log("codigo pedido", res.data)
                    setError(null);
                    setRequestedCode(true);
                }
            }
        ).catch(error => {
            console.log("Error - Request Code", error);
            setError("Email o teléfono no encontrado")
        })
    }

    const onSubmitNewPassword = (values) => {
        const confirmData = {
            code: values.code,
            password: values.password,
            confirm: values.confirmPassword
        }
        const valField = formRequestCode.getFieldValue("email");
        if(valField.includes("@")){
            confirmData.email = valField
        }else{
            if((/\d/).test(valField)){
                confirmData.phone = valField;
            }
        }
        // console.log("data__", confirmData)
        axios.post(`${apiUrl}/forgot/confirm`, confirmData)
            .then(res => {
                if (res.status === 200) {
                    // console.log("cambiada!"); 
                    setError(null);
                    setForgot(false);
                }
            }).catch(error => {
                console.log("Error - Confirm new password", error)
                setError("Datos Inválidos")
            })

    }

    return (
        <div style={{ position: "relative" }}>
            {
                !requestedCode ?

                    <div>
                        <h2>Restablecer contraseña</h2>
                        <Form requiredMark={false} form={formRequestCode} onFinish={onSubmitRequestCode}>
                            <Form.Item name="email"
                                rules={[
                                    { 
                                        required: true,
                                        message: 'Ingresa un email o teléfono válidos'
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value || !validator.email(value) || !validator.phone(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "Ingresa un email o teléfono válidos"
                                                )
                                            )
                                        }
                                    })
                                ]}>
                                <Input style={styles.input} placeholder="Email o Teléfono" prefix={<MailFilled style={styles.icons} />} />
                            </Form.Item>
                            <Form.Item>
                                <Button style={styles.button} htmlType="submit">Solicitar</Button>
                            </Form.Item>
                        </Form>
                    </div>

                    :
                    <div >
                        <h2>Restablecer contraseña</h2>
                        <Form requiredMark={false} form={formNewPassword} onFinish={onSubmitNewPassword}>
                            <Form.Item name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa un código'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || !validator.verificationCode(value)) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Código Inválido'));
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input style={styles.input} placeholder="Ingresa un código" prefix={<SafetyCertificateFilled style={styles.icons} />} />
                            </Form.Item>
                            <Form.Item
                                name="password"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa una contraseña',

                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || !validator.passwordSymbols(value)) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Contraseña inválida'));
                                        },
                                    }),

                                ]}
                                hasFeedback
                            >
                                <Input.Password style={styles.input} prefix={<LockFilled style={styles.icons} />} placeholder="Ingresa nueva contraseña" />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"

                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Confirma tu contraseña',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Las contraseñas no coinciden'));
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password style={styles.input} prefix={<LockFilled style={styles.icons} />} placeholder="Confirma nueva contraseña" />
                            </Form.Item>
                            <Form.Item>
                                <Button style={styles.button} htmlType="submit">Enviar</Button>
                            </Form.Item>

                        </Form>
                    </div>

            }
            <a style={{ color: "#949CAC", fontSize: "12px", textDecoration: "underline" }} onClick={() => setForgot(false)}>Volver a Iniciar Sesión</a>
            {
                error &&
                <Alert type="error" message={error} banner />
            }
        </div>
    )
}

export default Forgot

const email = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
const isNumber = RegExp(/^\d+$/);
const passwordSymbols = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/)
const phone = RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)


const validator = {
    email: (val) => {
        return email.test(val) ? false : "El email es inválido";
    },
    phone: (val) => {
        return phone.test(val) ? false : "El teléfono es inválido";
    },
    verificationCode: (val) => {
        return isNumber.test(val) && val.toString().length == 6 ? false : "El código es inválido"
    },
    passwordSymbols: (val) => {
        return passwordSymbols.test(val) ? false : "La Contraseña es inválida"
    }
};
